'use strict';

import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.

window.jQuery = $;

require('./lib/viewport'); // Voeg een class toe als element in beeld is (optioneel).

//require('foundation-sites');
require('lazyload');
//require('mmenu-js');
//require('@fancyapps/fancybox');
//require('slick-carousel');
require('forcefeed');

var raadhuis = require('./lib/default'); // Standaard JS functions (Slick slider, mmenu, sticky nav etc.).

// If you want to pick and choose which modules to include, comment out the above and uncomment the line below, go to the file and comment :
import './lib/foundation-explicit-pieces';

$(document).ready(function() {
    // Custom jQuery code here
    lazyload();

    $('body').removeClass('preload');

    raadhuis.forceFeed();
    raadhuis.scrollToAnchor('.scroll-to');

    $(document).foundation();
});
